import { useDyteSelector } from '@dytesdk/react-web-core';
import { useEffect, useRef, useState } from 'react';

import StudentSetupScreen from 'components/inClass/StudentSetupScreen';
import InClass from 'components/inClass/InClass';
import HuddleTransition from 'components/inClass/HuddleTransition';
import CameraErrorModal from 'components/inClass/CameraErrorModal';
import { BouncingDotsLoader } from 'components/common';
import { useMeeting } from 'hooks';
import { handleCameraRetry, setupCameraErrorTracking } from 'utils/inClass/cameraDeviceManager';

const Meeting = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);
  const { state, self } = useMeeting();
  const [showCameraError, setShowCameraError] = useState(false);
  const allRequestedTracks = useRef<MediaStreamTrack[]>([]);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined = undefined;
    if (state === 'connected-meeting') {
      setShowLoader(true);
      timer = setTimeout(() => {
        window.location.reload();
      }, 10000);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [state]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showLoader) {
      timer = setTimeout(() => {
        setShowLoader(false);
      }, 4000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [showLoader]);

  useEffect(() => {
    const cleanup = setupCameraErrorTracking({
      onCameraError: setShowCameraError,
      allRequestedTracks,
    });
    return cleanup;
  }, []);

  const handleRetryCamera = async (): Promise<void> => {
    await handleCameraRetry({
      onCameraError: setShowCameraError,
      self,
    });
  };

  return (
    <>
      {state === 'connected-meeting' || showLoader ? (
        <div className='flex flex-col items-center justify-center text-white bg-primary-800 w-[100vw] h-[100vh] gap-10'>
          <HuddleTransition />
          <BouncingDotsLoader />
        </div>
      ) : !roomJoined ? (
        <StudentSetupScreen />
      ) : (
        <InClass />
      )}
      <CameraErrorModal
        showCameraError={showCameraError}
        setShowCameraError={setShowCameraError}
        onRetry={handleRetryCamera}
      />
    </>
  );
};

export default Meeting;
