import { FC } from 'react';
import InClassModal from '../Modal';
import InClassButton from '../Button';

interface CameraErrorModalProps {
  showCameraError: boolean;
  setShowCameraError: React.Dispatch<React.SetStateAction<boolean>>;
  onRetry: () => Promise<void>;
}

const CameraErrorModal: FC<CameraErrorModalProps> = ({
  showCameraError,
  setShowCameraError,
  onRetry,
}) => (
  <InClassModal
    size={'md'}
    heading="UPRIO can't use your camera"
    children={
      <div className='flex flex-col items-center justify-center gap-4 text-white'>
        <span className='text-lg'>
          Another app is using your camera. Close the other app and try again.
        </span>
        <div className='flex justify-end w-full gap-2'>
          <InClassButton
            color='dark'
            title='Dismiss'
            rounded={false}
            onClick={() => setShowCameraError(false)}
            className='ml-4'
          />
          <InClassButton
            color='green'
            title='Try again'
            rounded={false}
            onClick={onRetry}
            className='ml-4'
          />
        </div>
      </div>
    }
    showCloseBtn={false}
    openModal={showCameraError}
    setOpenModal={setShowCameraError}
    className='text-white'
  />
);

export default CameraErrorModal;
