import { memo } from 'react';

interface TutorAvatarProps {
  firstName: string;
  lastName: string;
  profileImage?: string;
}

export const TutorAvatar = memo<TutorAvatarProps>(({ firstName, lastName, profileImage }) => {
  if (!firstName || !lastName) return null;

  if (profileImage) {
    return (
      <img
        src={profileImage}
        alt={`${firstName} ${lastName}`}
        className='object-cover w-20 h-20 rounded-full md:w-24 md:h-24'
        onError={(e) => {
          const target = e.currentTarget;
          target.onerror = null;
          target.style.display = 'none';
          if (target.parentElement) {
            target.parentElement.innerHTML = `
              <div class="flex items-center justify-center w-20 h-20 md:w-24 md:h-24 text-2xl md:text-3xl font-semibold text-white bg-blue-500 rounded-full">
                ${firstName[0]}${lastName[0]}
              </div>`;
          }
        }}
      />
    );
  }

  return (
    <div className='flex items-center justify-center w-20 h-20 text-2xl font-semibold text-white bg-blue-500 rounded-full md:w-24 md:h-24 md:text-3xl'>
      {firstName[0]}
      {lastName[0]}
    </div>
  );
});

TutorAvatar.displayName = 'TutorAvatar';
