import { Badge } from 'flowbite-react';
import { motion } from 'framer-motion';
import moment from 'moment';
import { IExamScheduleSubject } from 'types';

export default function SubjectCard({ subject }: { subject: IExamScheduleSubject }) {
  const getExamStatus = (date: Date | null) => {
    if (!date) return 'Date not set';
    return moment(date).isAfter(new Date()) ? 'Upcoming' : 'Completed';
  };
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className='p-4 transition-colors duration-200 bg-gray-100 rounded-lg hover:bg-gray-200'
    >
      <div className='flex items-center justify-between'>
        <div className='space-y-1'>
          <p className='font-medium text-gray-900'>{subject.subjectName}</p>
          <p className='text-sm text-neutral-500 body-medium'>
            {subject.examDate ? moment(subject.examDate).format('DD MMM YYYY') : 'Date not set'}
          </p>
        </div>
        <Badge
          className={`px-3 rounded-lg font-poppins text-xs font-semibold py-1.5 ${moment(subject.examDate).isAfter(new Date()) ? 'bg-semantic-green-500 text-white' : 'bg-gray-500 text-white'}`}
        >
          {getExamStatus(subject.examDate)}
        </Badge>
      </div>
    </motion.div>
  );
}
