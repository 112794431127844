import { DEVICE_TYPES } from 'configs/deviceType';
import { useEffect, useState } from 'react';

export const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState(DEVICE_TYPES.DESKTOP);
  const [orientation, setOrientation] = useState('landscape');

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      const isPortrait = window.matchMedia('(orientation: portrait)').matches;
      setOrientation(isPortrait ? 'portrait' : 'landscape');
      if (width <= 600) {
        setDeviceType(DEVICE_TYPES.MOBILE);
      } else if (width <= 1024) {
        setDeviceType(DEVICE_TYPES.TABLET);
      } else {
        setDeviceType(DEVICE_TYPES.DESKTOP);
      }
    };

    updateDeviceType();
    window.addEventListener('resize', updateDeviceType);
    window.addEventListener('orientationchange', updateDeviceType);

    return () => {
      window.removeEventListener('resize', updateDeviceType);
      window.removeEventListener('orientationchange', updateDeviceType);
    };
  }, []);

  return { deviceType, orientation };
};
