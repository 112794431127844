import qs from 'qs';
import { StateKeys } from 'types';

interface LinkParams {
  htmlLink: string;
  classId: number | undefined;
  studentId: number | undefined;
  currentState: StateKeys;
}

export const constructContentLink = ({
  htmlLink,
  classId,
  studentId,
  currentState,
}: LinkParams): string => {
  if (!htmlLink) return '';

  const queryParams = {
    classId,
    userId: studentId,
    userType: 'student',
    classPhase: currentState,
    classType: 'inClass',
  };

  const queryString = qs.stringify(queryParams);
  const connector = htmlLink.includes('?') ? '&' : '?';

  return `${htmlLink}${connector}${queryString}`;
};
