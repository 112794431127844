import {
  IGetNoteBookResponse,
  INotebookCaptureResponse,
} from './../../../types/inClass/evaluation/index.d';
import apiSlice from 'store/apiSlices';
import { EvalResponse, PostEvaluationRequest } from 'types';

const evaluationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    evaluation: builder.query<EvalResponse, { evalId: string }>({
      query: ({ evalId }) => ({
        url: `/v1/in-class/class-content/evaluation/${evalId}`,
      }),
    }),
    postEvaluation: builder.mutation<void, { body: PostEvaluationRequest }>({
      query: ({ body }) => ({
        url: `v2/in-class/raw-answer/`,
        method: 'POST',
        body,
      }),
    }),
    addNote: builder.mutation<INotebookCaptureResponse, { formData: FormData }>({
      query: ({ formData }) => ({
        url: `/v2/note-pages/`,
        method: 'POST',
        body: formData,
      }),
    }),

    getNoteBookCapture: builder.query<
      IGetNoteBookResponse,
      { practiceSheetId?: string; evaluationId: string }
    >({
      query: ({ practiceSheetId, evaluationId }) => ({
        method: 'GET',
        url: '/v2/note-pages/',
        params: {
          practiceSheetId,
          evaluationId,
        },
      }),
    }),
  }),
});

export const {
  useLazyEvaluationQuery,
  usePostEvaluationMutation,
  useAddNoteMutation,
  useGetNoteBookCaptureQuery,
} = evaluationApiSlice;
