interface CameraUtilsConfig {
  onCameraError: (show: boolean) => void;
  allRequestedTracks: React.MutableRefObject<MediaStreamTrack[]>;
  self?: {
    enableVideo: () => Promise<void>;
  };
}

export const setupCameraErrorTracking = ({
  onCameraError,
  allRequestedTracks,
}: CameraUtilsConfig) => {
  const originalGetUserMedia = navigator.mediaDevices.getUserMedia.bind(navigator.mediaDevices);

  navigator.mediaDevices.getUserMedia = async (
    constraints: MediaStreamConstraints,
  ): Promise<MediaStream> => {
    try {
      const stream = await originalGetUserMedia(constraints);
      stream.getTracks().forEach((track: MediaStreamTrack) => {
        allRequestedTracks.current.push(track);
        track.onended = () => {
          const index = allRequestedTracks.current.indexOf(track);
          if (index > -1) {
            allRequestedTracks.current.splice(index, 1);
          }
        };
      });

      const hasActiveTracks = allRequestedTracks.current.some(
        (track: MediaStreamTrack) =>
          track.readyState === 'live' && track.enabled && track.kind === 'video',
      );
      onCameraError(!hasActiveTracks);

      return stream;
    } catch (error: unknown) {
      if (error instanceof Error) {
        if (
          error.name === 'NotReadableError' ||
          error.message.includes('Could not start video source')
        ) {
          onCameraError(true);
        }
      }
      // Instead of throwing the error, return a rejected promise
      return Promise.reject(error);
    }
  };

  return () => {
    navigator.mediaDevices.getUserMedia = originalGetUserMedia;
    allRequestedTracks.current.forEach((track: MediaStreamTrack) => track.stop());
    allRequestedTracks.current = [];
  };
};

export const handleCameraRetry = async ({
  onCameraError,
}: Pick<CameraUtilsConfig, 'onCameraError' | 'self'>): Promise<void> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    stream.getTracks().forEach((track: MediaStreamTrack) => track.stop());
    onCameraError(false);
  } catch (err) {
    onCameraError(true);
  }
};
