import html2canvas from 'html2canvas';
import { useCallback, useEffect, useState } from 'react';
import qs from 'qs';

import { useInclassLogger, useMeeting, useNotification, useSocketSubscribe } from 'hooks';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import {
  selectMyCurrentState,
  setMyCurrentState,
  setStudentsCurrentStates,
} from 'store/slice/inClassConfig.slice';
import {
  ActiveTab,
  EvalTrigger,
  FileMessagePayload,
  IEvalActive,
  IEvalStatus,
  IHtmlLinks,
  IPluginStatus,
  IPluginStatusSocketEvent,
  ISlides,
  ISteps,
  NotebookCaptureData,
  RenderState,
  StateKeys,
} from 'types';

interface ICurrentStatesOfStudents {
  coach_basic: string[];
  coach_intermediate: string[];
  coach_advanced: string[];
  teach: string[];
  [key: string]: string[];
}

import { CONFIG, EVALUATIONS, SCREENSHOT_MESSAGE } from 'configs';
import { useGetLectureQuery } from 'store/apiSlices/inClass/lecture.apiSlice';
import {
  currentLectureId,
  IElementDetails,
  setCurrentLectureId,
  setEvalId,
  setSlideData,
  setTutorRoomChange,
} from 'store/slice/content.slice';
import { setMultiLecture } from 'store/slice/lecture.slice';

export const useSocketHandlers = (
  setRender: React.Dispatch<React.SetStateAction<RenderState>>,
  setActiveMorphcast: React.Dispatch<React.SetStateAction<boolean>>,
  studentName: string,
  setPluginStatus: React.Dispatch<React.SetStateAction<IPluginStatus>>,
  setEvalStatus: React.Dispatch<React.SetStateAction<IEvalStatus>>,
) => {
  // States
  const [handRaised, setHandRaised] = useState<Record<string, boolean>>({});
  const [noteBookCaptureData, setNoteBookCaptureData] = useState<NotebookCaptureData>({
    studentId: 0,
    evaluationId: '',
    elementId: '',
    page: 0,
    tutorId: 0,
  });
  const [evalTrigger, setEvalTrigger] = useState<EvalTrigger>({
    evalId: '',
    evalType: '',
    status: false,
    meetingId: '',
    studentIds: [],
    loading: false,
    submit: '',
  });
  const [tutorActiveRoom, setTutorActiveRoom] = useState<string>(CONFIG.ROOMS.TEACH);

  const myCurrentState = useAppSelector(selectMyCurrentState) || CONFIG.ROOMS.TEACH;

  // Redux
  const dispatch = useAppDispatch();
  const activeLectureId = useAppSelector(currentLectureId);

  //Hooks
  const { meeting, studentId, classId, tutorId } = useMeeting();

  // Datadog
  const { datadogLog } = useInclassLogger();

  // Notification
  const triggerNotification = useNotification();

  // API call
  const { data: LectureData } = useGetLectureQuery({
    classId: classId ? String(classId) : 'null',
  });

  // Lecture id change socket event
  const lectureChangeHandler = useCallback(
    ({ lectureId }: { lectureId: string }) => {
      try {
        dispatch(setCurrentLectureId(lectureId));
      } catch (e) {
        console.error('Error while setting lecture data : ', e);
      }
    },
    [dispatch],
  );

  useSocketSubscribe<{ lectureId: string }>('lectureChangeToClient', lectureChangeHandler);

  useEffect(() => {
    const lectureData = LectureData?.data?.lectures?.find(
      (lecture) => lecture?.configId === activeLectureId,
    );
    if (lectureData) {
      dispatch(setMultiLecture(lectureData));
    }
  }, [LectureData, dispatch, activeLectureId]);

  // Hand Raise socket event
  const handler = useCallback((data: { [studentId: string]: boolean }) => {
    setHandRaised((prev) => ({ ...prev, ...data }));
  }, []);

  useSocketSubscribe('handRaiseToClient', handler);

  // HTML slides socket event
  const htmlSlidesHandler = useCallback(
    ({
      htmlLink,
      slide,
      step,
      elementDetails,
      pluginStatus,
      currentState,
      studentID,
      onTabChange,
    }: {
      htmlLink: {
        teach: string;
        coach_basic: string;
        coach_intermediate: string;
        coach_advanced: string;
      };
      slide: number;
      step: number;
      elementDetails: IElementDetails;
      pluginStatus: IPluginStatusSocketEvent;
      currentState: StateKeys;
      studentID: number;
      onTabChange: boolean;
    }) => {
      if (studentID && studentID !== studentId) return;
      if (myCurrentState === currentState) {
        const contentLink = htmlLink?.[myCurrentState];
        dispatch(setTutorRoomChange(onTabChange));
        setRender((prev) => ({
          ...prev,
          slides: pluginStatus?.[myCurrentState]?.slide?.active,
        }));
        setPluginStatus(pluginStatus?.[myCurrentState]);
        dispatch(
          setSlideData({
            step: step,
            slide: slide,
            htmlLink: contentLink,
            elementDetails: elementDetails,
          }),
        );
        datadogLog(`Slides initialized for student ${studentName} (slides modal opened)`, {
          htmlLink: contentLink,
          slide: slide ? slide.toString() : '',
          step: step ? step.toString() : '',
        });
      }
    },
    [datadogLog, dispatch, myCurrentState, setPluginStatus, setRender, studentId, studentName],
  );

  useSocketSubscribe<{
    htmlLink: {
      teach: string;
      coach_basic: string;
      coach_intermediate: string;
      coach_advanced: string;
    };
    slide: number;
    step: number;
    elementDetails: IElementDetails;
    pluginStatus: IPluginStatusSocketEvent;
    currentState: StateKeys;
    studentID: number;
    onTabChange: boolean;
  }>('htmlSlidesEveToClient', htmlSlidesHandler);

  // Notebook socket event
  const notebookCaptureHandler = useCallback(
    (data: string) => {
      const parsedData = JSON.parse(data);
      setNoteBookCaptureData((prev) => ({ ...prev, ...parsedData }));
      setRender((prev) => ({ ...prev, notebook: true }));
      setActiveMorphcast(false);
      datadogLog(
        `Notebook capture initialized for student ${studentName} (notebook capture modal opened)`,
      );
    },
    [datadogLog, setRender, setActiveMorphcast, studentName],
  );

  useSocketSubscribe<string>('studentNotebookEveToClient', notebookCaptureHandler);

  // Tutor cancel notebook capture socket event
  const tutorCancelScreenshotHandler = useCallback(() => {
    setRender((prev) => ({ ...prev, notebook: false }));
    setActiveMorphcast(true);
    datadogLog(`Tutor canceled notebook capture for student ${studentName}`);
  }, [datadogLog, setRender, setActiveMorphcast, studentName]);

  useSocketSubscribe<string>('tutorCancelScreenshotEveToClient', tutorCancelScreenshotHandler);

  // Tutor restart poll socket event
  const restartPollHandler = useCallback(() => {
    setRender((prev) => ({ ...prev, polls: true }));
    triggerNotification('Tutor restarted polls');
    datadogLog(`Poll restarted for student ${studentName}`);
  }, [datadogLog, setRender, studentName, triggerNotification]);

  useSocketSubscribe<{ evalId: string; currentState: string }>(
    'pollRestartToClient',
    restartPollHandler,
  );

  // Restart meeting socket event
  const restartMeeting = useCallback(() => {
    window.location.reload();
  }, []);

  useSocketSubscribe<string>('startHuddleRefreshToClient', restartMeeting);

  // Evaluation trigger socket event
  const evalToClientHandler = useCallback(
    (data: string) => {
      const parsedData = JSON.parse(data);
      if (parsedData.currentRoom === myCurrentState) {
        if (
          parsedData?.evalType === 'practice_evaluations' &&
          parsedData?.studentIds?.includes(Number(studentId))
        ) {
          setEvalTrigger((prev) => ({ ...prev, ...parsedData }));
        } else if (parsedData?.evalType !== 'practice_evaluations') {
          setEvalTrigger((prev) => ({ ...prev, ...parsedData }));
        }
        datadogLog(`${parsedData.evalType} triggered for student ${studentName}`);
      }
    },
    [myCurrentState, studentId, datadogLog, studentName],
  );

  useSocketSubscribe<string>('evaluationToClient', evalToClientHandler);

  // Refresh huddle socket event
  const refreshHuddleHandler = useCallback(() => {
    setTimeout(() => {
      meeting?.connectedMeetings?.getConnectedMeetings();
    }, 4000);
  }, [meeting?.connectedMeetings]);

  useSocketSubscribe<string>('refreshHuddleToClient', refreshHuddleHandler);

  // Meeting sync socket event
  const currentMeetingStateHandler = useCallback(
    (data: {
      activeTab: ActiveTab;
      evaluationState: {
        teach: { status: boolean; id: string; type: string };
        coach_basic: { status: boolean; id: string; type: string };
        coach_intermediate: { status: boolean; id: string; type: string };
        coach_advanced: { status: boolean; id: string; type: string };
      };
      meetingId: string;
      slide: number;
      currentRoom: string;
    }) => {
      if (
        !data?.evaluationState?.[myCurrentState]?.type &&
        !data?.evaluationState?.[myCurrentState]?.id
      ) {
        setRender((prev) => ({
          ...prev,
          polls: false,
          oral: false,
          written: false,
        }));
        dispatch(setEvalId(''));
        datadogLog(`All evaluations reset for student ${studentName} through sync huddle`);
      } else if (data?.evaluationState?.[myCurrentState]?.type === 'poll_evaluations') {
        setRender((prev) => ({
          ...prev,
          written: false,
          oral: false,
          polls: data?.evaluationState?.[myCurrentState]?.status,
        }));
        dispatch(setEvalId(data?.evaluationState?.[myCurrentState]?.id));
        datadogLog(
          `${data?.evaluationState?.[myCurrentState]?.type} and slideNo: ${data?.slide} triggered for student ${studentName} through sync huddle`,
        );
      } else if (
        data?.evaluationState?.[myCurrentState]?.type === EVALUATIONS.NOTEBOOK ||
        data?.evaluationState?.[myCurrentState]?.type === EVALUATIONS.PRACTICE
      ) {
        setRender((prev) => ({
          ...prev,
          polls: false,
          oral: false,
          written: data?.evaluationState?.[myCurrentState]?.status,
        }));
        dispatch(setEvalId(data?.evaluationState?.[myCurrentState]?.id));
        datadogLog(
          `${data?.evaluationState?.[myCurrentState]?.type} triggered for student ${studentName} through sync huddle`,
        );
      }
      meeting.meta.setSelfActiveTab({ type: data?.activeTab?.type, id: data?.activeTab?.id }, 0);
    },
    [datadogLog, dispatch, meeting.meta, myCurrentState, setRender, studentName],
  );

  useSocketSubscribe<{
    activeTab: ActiveTab;
    evaluationState: {
      teach: { status: boolean; id: string; type: string };
      coach_basic: { status: boolean; id: string; type: string };
      coach_intermediate: { status: boolean; id: string; type: string };
      coach_advanced: { status: boolean; id: string; type: string };
    };
    meetingId: string;
    slide: number;
    currentRoom: string;
  }>('currentMeetStateToClient', currentMeetingStateHandler);

  // Reconnect socket event
  const disconnectHandler = useCallback(() => {
    setTimeout(() => {
      meeting?.connectedMeetings?.getConnectedMeetings();
    }, 2000);
  }, [meeting?.connectedMeetings]);

  useSocketSubscribe<string>('disconnectToClient', disconnectHandler);

  // Student side screenshot socket event
  const takeScreenshotHandler = useCallback(
    async (data: { studentId: string; classId: string }) => {
      if (String(studentId) !== data.studentId || String(classId) !== data.classId) return;

      const div = document.getElementById('mainClass');
      if (!div) return;

      const sendScreenshot = async (canvas: HTMLCanvasElement) => {
        const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));
        if (!blob) return;

        const file = new File([blob], 'screenshot.png', { type: 'image/png' });
        const fileMessage: FileMessagePayload = { type: 'file', file };

        meeting.chat.sendMessage(
          {
            type: 'text',
            message: SCREENSHOT_MESSAGE,
          },
          [tutorId],
        );
        meeting.chat.sendMessage(fileMessage, [tutorId]);
      };

      const canvas = await html2canvas(div, {
        logging: false,
        useCORS: true,
        backgroundColor: null,
      });
      await sendScreenshot(canvas);
    },
    [classId, meeting.chat, studentId, tutorId],
  );

  useSocketSubscribe<{ studentId: string; classId: string }>(
    'takeScreenshotToClient',
    takeScreenshotHandler,
  );

  // Current room to student socket event
  const currentRoomHandler = useCallback((data: { activeRoom: string }) => {
    setTutorActiveRoom(data.activeRoom);
  }, []);

  useSocketSubscribe<{ activeRoom: string }>('currentRoomOfTutorToClient', currentRoomHandler);

  // Plugin status to student socket event
  const pluginStatusHandler = useCallback(
    (data: { pluginStatus: IPluginStatusSocketEvent }) => {
      setRender((prev) => ({
        ...prev,
        slides: data.pluginStatus?.[myCurrentState]?.slide?.active,
      }));
      setPluginStatus(data.pluginStatus?.[myCurrentState as StateKeys]);
    },
    [myCurrentState, setRender, setPluginStatus],
  );

  useSocketSubscribe<{
    pluginStatus: IPluginStatusSocketEvent;
  }>('pluginStatusEveToClient', pluginStatusHandler);

  // Evaluation is Progress socket event
  const evalActiveHandler = useCallback(
    (data: IEvalActive) => {
      setEvalStatus(data.evalInProgress);
    },
    [setEvalStatus],
  );

  useSocketSubscribe<IEvalActive>('evalActiveEveToClient', evalActiveHandler);

  // Current room socket event
  const myCurrentRoomState = useCallback(
    (data: ICurrentStatesOfStudents) => {
      for (const key in data) {
        if (data[key].includes(String(studentId))) {
          dispatch(setMyCurrentState(key as StateKeys));
        }
      }
      dispatch(setStudentsCurrentStates(data));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [studentId],
  );

  useSocketSubscribe<ICurrentStatesOfStudents>(
    'currentStatesOfStudentsToClient',
    myCurrentRoomState,
  );

  const updateOtherRoomsContent = useCallback(
    ({
      htmlLinks,
      slides,
      steps,
      elementDetails,
      pluginStatus,
    }: {
      htmlLinks: IHtmlLinks;
      slides: ISlides;
      steps: ISteps;
      elementDetails: IElementDetails;
      pluginStatus: IPluginStatusSocketEvent;
    }) => {
      const contentLink = htmlLinks?.[myCurrentState];
      setPluginStatus(pluginStatus?.[myCurrentState]);
      dispatch(
        setSlideData({
          step: steps?.[myCurrentState],
          slide: slides?.[myCurrentState],
          htmlLink: contentLink,
          elementDetails: elementDetails,
        }),
      );
    },
    [dispatch, myCurrentState, setPluginStatus],
  );

  useSocketSubscribe<{
    htmlLinks: IHtmlLinks;
    slides: ISlides;
    steps: ISteps;
    elementDetails: IElementDetails;
    pluginStatus: IPluginStatusSocketEvent;
  }>('updateOtherRoomsContentToClient', updateOtherRoomsContent);

  const contentReloadHandler = useCallback(
    ({
      htmlLink,
      slide,
      step,
      classLevel,
    }: {
      htmlLink: string;
      slide: number;
      step: number;
      classLevel: StateKeys;
    }) => {
      if (myCurrentState === classLevel) {
        const urlWithParams = `${htmlLink}?${qs.stringify({
          t: Date.now(),
        })}`;
        dispatch(setSlideData({ step, slide, htmlLink: urlWithParams }));
      }
    },
    [dispatch, myCurrentState],
  );

  useSocketSubscribe<{
    htmlLink: string;
    slide: number;
    step: number;
    classLevel: StateKeys;
  }>('contentReloadToClient', contentReloadHandler);

  return {
    handRaised,
    noteBookCaptureData,
    evalTrigger,
    tutorActiveRoom,
  };
};
