import { memo } from 'react';
import Countdown from 'react-countdown';
import { TimeUnit } from '../TimeUnit';
import { TimeSeparator } from '../TimeSeparator';
import { CountdownTimerProps } from 'types/inClass/PreClassScreen';

export const CountdownTimer = memo(({ date }: CountdownTimerProps) => (
  <Countdown
    date={date}
    renderer={({ hours, minutes, seconds }) => (
      <div className='flex items-center justify-center space-x-4 md:space-x-10'>
        <TimeUnit value={hours} label='hrs' />
        <TimeSeparator />
        <TimeUnit value={minutes} label='min' />
        <TimeSeparator />
        <TimeUnit value={seconds} label='sec' />
      </div>
    )}
  />
));

CountdownTimer.displayName = 'CountdownTimer';
