import { ExamScheduleIcon } from 'assets/svg';
import { Card, Tooltip } from 'flowbite-react';
import { motion } from 'framer-motion';

import { useGetExamScheduleQuery } from 'store/apiSlices/studentDashboard.apiSlice';
import { IExamScheduleProps } from 'types';
import SubjectCard from './SubjectCard';

export default function ExamSchedule({ examQueryData }: IExamScheduleProps) {
  const { data: examScheduleData } = useGetExamScheduleQuery(
    {
      filter: {
        schoolId: Number(examQueryData?.schoolId),
        gradeId: String(examQueryData?.gradeId),
        boardId: String(examQueryData?.boardId),
      },
    },
    {
      skip: !examQueryData,
    },
  );

  if (!examScheduleData?.docs?.length) {
    return (
      <div className='flex flex-col items-center justify-center p-8 rounded-xl font-poppins bg-primary-50'>
        <div className='relative'>
          <div className='absolute rounded-full -inset-1 bg-gradient-to-r from-blue-100 to-purple-100 blur opacity-30 animate-pulse'>
            <img src={ExamScheduleIcon} alt='Exam Pen' className='object-contain w-full h-full' />
          </div>
        </div>
        <h3 className='mt-6 text-xl font-semibold text-gray-700'>No Exams Scheduled</h3>
        <p className='mt-2 text-neutral-500'>
          You're all caught up! Check back later for upcoming exams.
        </p>
      </div>
    );
  }

  return (
    <div className='w-full overflow-x-scroll scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 scrollbar-medium'>
      <div className='flex min-w-full gap-4 pb-4'>
        {examScheduleData?.docs[0]?.examSchedule?.map((exam) => (
          <motion.div
            key={exam?.id}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.3 }}
            className='min-w-[320px] max-w-[400px] w-full'
          >
            <Card className='h-full rounded-2xl bg-[#FFFFFF]'>
              <div className='space-y-4'>
                <div className='flex items-center gap-3'>
                  <div className='p-2 bg-[#30F2EA] rounded-md'>
                    <img
                      src={ExamScheduleIcon}
                      alt='Exam Pen'
                      className='object-contain w-full h-full'
                    />
                  </div>
                  <Tooltip content={exam?.examName}>
                    <h3 className='text-base font-semibold text-gray-900 truncate max-w-[200px] capitalize font-poppins'>
                      {exam?.examName}
                    </h3>
                  </Tooltip>
                </div>

                <div className='space-y-3'>
                  {exam.subjects?.map((subject) => (
                    <SubjectCard key={subject?.id} subject={subject} />
                  ))}
                </div>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>
    </div>
  );
}
