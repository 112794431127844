import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExclamationWarn, SectionHeadingLogo } from 'assets/svg';
import { Accordian, Button, Modal, ModalBottomSheet } from 'components/common';
import { PracticeEvalContent, PracticeSheetSubmission } from 'components/screens';

import {
  EVALUATED_STATUS,
  EVALUATION_SUBMISSION_STATUS,
  LOG_TYPE,
  STUDENT_PATHS,
  USER_TYPE,
} from 'configs';
import { useLogger, useWindowDimensions } from 'hooks';
import { IQRCodeQuestions } from 'types';
import { selectUserType } from 'store/slice/studentRegister.slice';
import { useAppSelector } from 'hooks/store';
import PortableTextWithLatex from 'components/common/PortableTextWithLatex';

export default function QrCodeQuestions({
  params,
  evalName,
  isLoading,
  questionData,
  practiceQuestionsData,
  handleQrCodeCapture,
  refetch,
  setNoteId,
  submissionStatus,
  evaluationStatus,
}: IQRCodeQuestions) {
  const { logInfo } = useLogger();
  const navigate = useNavigate();
  const { isMobileScreen } = useWindowDimensions();
  const userType = useAppSelector(selectUserType);
  const [showSubmissionModal, setShowSubmissionModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const handlePracticeSheetSubmission = () => {
    const { evaluationId, practiceSheetId, studentId, questionId } = params;
    logInfo(
      `Submitting practice sheet for the evaluationId -> ${evaluationId}, practiceSheetId -> ${practiceSheetId} , studentId -> ${studentId} , questionId -> ${questionId} `,
      {
        evaluationId,
        practiceSheetId,
        studentId,
        questionId,
      },
      LOG_TYPE.INFO,
    );
    setShowSubmissionModal(true);
  };

  useEffect(() => {
    if (showSubmissionModal) {
      refetch();
    }
  }, [refetch, showSubmissionModal]);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setShowModal(true);
  };

  return (
    <>
      <div className='flex flex-col justify-between flex-1 w-full h-full'>
        <div>
          <h1 className='items-center px-3 py-10 text-xl font-bold tracking-wider uppercase md:px-6 md:flex font-sora text-start md:text-lg lg:text-2xl xl:leading-6'>
            <img src={SectionHeadingLogo} alt='uprio section logo' className='my-4 mr-5 md:my-0' />
            {evalName}
          </h1>

          {userType === USER_TYPE.GUARDIAN &&
            submissionStatus &&
            [
              EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED,
              EVALUATION_SUBMISSION_STATUS.BLANK_SUBMISSION,
              EVALUATION_SUBMISSION_STATUS.WRONG_SUBMISSION,
              EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION,
            ]?.includes(submissionStatus) && (
              <div className='py-2 px-2 text-center'>
                <p className='font-sora text-primary-700 title-regular'>
                  Note: Ensure you switch to the student account before submitting answers.
                </p>
              </div>
            )}
          {/* Practice Paper question */}
          <div className='p-3 mb-10 w-screen md:p-6'>
            {questionData?.map((question, index) => (
              <div
                key={question._id}
                className={`p-6 relative my-4 w-full transition-all duration-300 rounded-2xl shadow-lg border-2 ${
                  question?.notebookCaptured
                    ? 'bg-semantic-green-200 border-semantic-green-200'
                    : 'bg-white text-neutral-800 border-neutral-300 hover:bg-gray-100'
                }`}
              >
                {question?.notebookCaptured ? (
                  <>
                    <span className='absolute top-0 right-0 px-3 py-1 text-sm font-medium text-white bg-green-500 rounded-l-full rounded-tr-full shadow-md'>
                      <span className='mr-2 animate-bounce'>🎉</span>
                      Submitted
                    </span>

                    {userType === USER_TYPE.STUDENT &&
                      (submissionStatus === EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED ||
                        submissionStatus === EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION) && (
                        <span
                          className='absolute bottom-0 right-0 px-6 py-1 text-sm font-medium text-white capitalize rounded-l-full rounded-br-full shadow-md hover:cursor-pointer bg-primary-500'
                          onClick={() => {
                            handleQrCodeCapture(question, index);
                            setNoteId(question?.noteId || 0);
                          }}
                        >
                          Re-capture
                        </span>
                      )}
                  </>
                ) : submissionStatus === EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE ||
                  submissionStatus === EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE ? (
                  <span className='absolute top-0 right-0 px-3 py-1 text-sm font-medium text-white bg-semantic-red-400 rounded-l-full rounded-tr-full shadow-md'>
                    <span className='mr-2 animate-bounce'>❓</span>
                    Not attempted
                  </span>
                ) : (
                  ''
                )}
                <div className='flex flex-col justify-between md:flex-row md:items-center'>
                  <h5 className='text-lg font-bold text-primary-500'>Question:</h5>
                </div>
                <div className='flex flex-col items-start justify-between mb-4 text-lg font-medium break-words md:flex-row md:items-center lg:text-xl'>
                  <div
                    className={`flex-1 ${question?.notebookCaptured ? 'text-neutral-850' : 'text-neutral-800'}`}
                  >
                    <PracticeEvalContent data={question?.question_description} />
                  </div>
                  <div className='flex items-center mt-4 md:mt-0 md:ml-4'>
                    {!question?.notebookCaptured &&
                      userType === USER_TYPE.STUDENT &&
                      submissionStatus &&
                      ![
                        EVALUATION_SUBMISSION_STATUS.CORRECT_SUBMISSION,
                        EVALUATION_SUBMISSION_STATUS.SUBMITTED_WITHIN_DEADLINE,
                        EVALUATION_SUBMISSION_STATUS.SUBMITTED_POST_DEADLINE,
                      ].includes(submissionStatus) && (
                        <Button
                          text='Capture'
                          onClick={() => {
                            handleQrCodeCapture(question, index);
                            setNoteId(0);
                          }}
                          className='px-2 text-base font-semibold text-white transition-colors duration-200 rounded-lg shadow-md bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50'
                        />
                      )}
                  </div>
                  {question?.notebookCaptured && (
                    <div className='flex items-center justify-end flex-1'>
                      <button
                        key={question?._id + ' ' + question?.question_description}
                        onClick={() =>
                          handleImageClick(
                            evaluationStatus === EVALUATED_STATUS.EVALUATED
                              ? question?.capturedImages[0]?.annotated
                              : question?.capturedImages[0]?.original,
                          )
                        }
                        className='w-24 h-24 mr-1 overflow-hidden border border-gray-300 rounded-md'
                      >
                        <img
                          src={
                            evaluationStatus === EVALUATED_STATUS.EVALUATED
                              ? question?.capturedImages[0]?.annotated
                              : question?.capturedImages[0]?.original
                          }
                          alt={`Capture ${index + 1}`}
                          className='object-cover w-full h-full'
                        />
                      </button>
                    </div>
                  )}
                </div>

                {question.notebookCaptured && evaluationStatus === EVALUATED_STATUS.EVALUATED && (
                  <Accordian
                    title='Show answer'
                    collapseAll={true}
                    className='p-3 mb-3 break-words border-2 overflow-x-auto border-neutral-50 rounded-xl'
                  >
                    <PortableTextWithLatex value={question.answer} />
                  </Accordian>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Submitting the practice paper */}
        {userType === USER_TYPE.STUDENT &&
          (submissionStatus === EVALUATION_SUBMISSION_STATUS.NOT_SUBMITTED ||
            submissionStatus === EVALUATION_SUBMISSION_STATUS.PARTIAL_SUBMISSION) && (
            <div className='sticky bottom-0 z-10 flex flex-col items-center gap-2 px-2 py-5 shadow-inner bg-primary-50 rounded-t-3xl'>
              <div className='flex items-center p-2 rounded-lg bg-semantic-orange-200 text-neutral-900 body-semibold'>
                <img src={ExclamationWarn} alt='info' className='w-5 h-5 mr-2' />
                Submit your practice sheet for evaluation.
              </div>
              <div className='flex justify-start mb-7'>
                <Button
                  type='button'
                  className='text-white rounded-lg bg-semantic-green-500 enabled:hover:bg-semantic-green-500'
                  text='Submit Practice Evaluation'
                  isLoading={isLoading}
                  onClick={handlePracticeSheetSubmission}
                />
              </div>
            </div>
          )}
      </div>

      {/* Modal for getting confirmation for the submitting the practice sheet */}
      {showSubmissionModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              openModal={showSubmissionModal}
              setOpenModal={setShowSubmissionModal}
              showCloseBtn={false}
              children={
                <PracticeSheetSubmission
                  totalQuestionCount={
                    practiceQuestionsData?.data[0]?.totalQuestions ||
                    practiceQuestionsData?.data[0]?.questions?.length ||
                    0
                  }
                  answeredCount={practiceQuestionsData?.data[0]?.captureAnswerCount || 0}
                  closeSubmissionModal={setShowSubmissionModal}
                  closeDrawer={() => {
                    setTimeout(() => {
                      navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
                        replace: true,
                      });
                    }, 1000);
                  }}
                  practiceSheetId={params?.practiceSheetId}
                  evaluationId={params?.evaluationId}
                />
              }
            />
          ) : (
            <Modal
              openModal={showSubmissionModal}
              setOpenModal={setShowSubmissionModal}
              size={'xl'}
              showCloseBtn={false}
              children={
                <PracticeSheetSubmission
                  totalQuestionCount={
                    practiceQuestionsData?.data[0]?.totalQuestions ||
                    practiceQuestionsData?.data[0]?.questions?.length ||
                    0
                  }
                  answeredCount={practiceQuestionsData?.data[0]?.captureAnswerCount || 0}
                  closeSubmissionModal={setShowSubmissionModal}
                  closeDrawer={() => {
                    setTimeout(() => {
                      navigate(STUDENT_PATHS.STUDENT_PRACTICE, {
                        replace: true,
                      });
                    }, 1000);
                  }}
                  practiceSheetId={params?.practiceSheetId}
                  evaluationId={params?.evaluationId}
                />
              }
            />
          )}
        </div>
      )}

      {/* Modal for the selected Image will be appeared in a Zoomed manner */}
      {showModal && (
        <div>
          {isMobileScreen ? (
            <ModalBottomSheet
              setOpenModal={setShowModal}
              openModal={showModal}
              showCloseBtn={true}
              children={
                <div className='customScrollBar'>
                  <h1 className='text-xl font-semibold text-center font-sora text-neutral-800'>
                    Submitted Answer:
                  </h1>
                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
            />
          ) : (
            <Modal
              children={
                <div className='customScrollBar'>
                  <h1 className='font-semibold text-center font-sora text-primary-500 lg:text-xl xl:text-2xl'>
                    Submitted Answer:
                  </h1>
                  <div className='flex items-center justify-center'>
                    <img
                      src={selectedImage}
                      alt='Full Size'
                      className='max-w-full max-h-screen p-2 rounded-xl'
                    />
                  </div>
                </div>
              }
              showCloseBtn={true}
              openModal={showModal}
              setOpenModal={setShowModal}
              size={'5xl'}
            />
          )}
        </div>
      )}
    </>
  );
}
